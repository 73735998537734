import barba from "@barba/core";
import gsap from "gsap";
import { createApp } from "vue";
import SeatPlanSite from "./components/site/SeatPlan.vue";
import NoSeatPlan from "./components/site/NoSeatPlan.vue";
import SeatPlanCanvas from "./components/site/SeatPlanCanvas.vue";
import OrderSummary from "./components/site/OrderSummary.vue";
import Subscription from "./components/site/Subscription.vue";
import { ScrollTrigger } from "gsap/ScrollTrigger";

document.addEventListener("DOMContentLoaded", () => {
    barba.init({
        transitions: [
            {
                name: "fade",
                leave(data) {
                    const done = this.async();
                    gsap.to(data.current.container, {
                        opacity: 0,
                        duration: 0.5,
                        onComplete: done,
                    });
                    // append body an element
                    const el = document.createElement("div");
                    el.id = "loader-logo";
                    el.style.position = "fixed";
                    el.style.top = "0";
                    el.style.left = "0";
                    el.style.width = "100%";
                    el.style.height = "100%";
                    el.style.zIndex = "1000";
                    el.style.display = "flex";
                    el.style.justifyContent = "center";
                    el.style.alignItems = "center";
                    el.style.backgroundColor = "#000c3b";
                    el.style.opacity = "0";
                    el.style.transition = "opacity 0.5s";
                    el.innerHTML = document.body.querySelector(
                        ".navbar .navbar-brand img"
                    ).outerHTML;
                    document.body.appendChild(el);

                    setTimeout(() => {
                        document.body.querySelector(
                            "#loader-logo"
                        ).style.opacity = 1;
                    }, 100);
                },
                enter(data) {
                    document.body.querySelector(
                        "#loader-logo"
                    ).style.opacity = 0;
                    document.body
                        .querySelector("#loader-logo")
                        .addEventListener("transitionend", () => {
                            document.body
                                .querySelector("#loader-logo")
                                .remove();
                        });
                    gsap.from(data.next.container, {
                        opacity: 0,
                        duration: 0.5,
                        onComplete: () => {
                            // Additional code if needed
                            const siteApp = createApp({});
                            siteApp.component("no-seat-plan", NoSeatPlan);
                            siteApp.component(
                                "seat-plan-canvas",
                                SeatPlanCanvas
                            );
                            siteApp.component("order-summary", OrderSummary);
                            siteApp.component("seat-plan", SeatPlanSite);
                            siteApp.component("subscription", Subscription);
                            siteApp.mount("#site-app");
                            gsap.registerPlugin(ScrollTrigger);
                            gsap.utils
                                .toArray(".animate")
                                .forEach((element) => {
                                    gsap.from(element, {
                                        scrollTrigger: {
                                            trigger: element,
                                            start: "top 80%",
                                        },
                                        opacity: 0,
                                        y: 100,
                                        duration: 1,
                                    });
                                    gsap.to(element, {
                                        scrollTrigger: {
                                            trigger: element,
                                            start: "top 80%",
                                        },
                                        opacity: 1,
                                        y: 0,
                                        duration: 1,
                                        onComplete() {
                                            if (element.id === "sitz-kaufen")
                                                element.style.transform =
                                                    "none";
                                        },
                                    });
                                });
                        },
                    });
                },
            },
        ],
    });
    async function changeBackgroundColor(color, otherEl) {
        return new Promise((resolve) => {
            document.body.style.backgroundColor = color;
            setTimeout(() => {
                resolve();
            }, 500);
        });
    }
});
